window.onload = () => {
  let prevScrollpos = window.pageYOffset;
  window.onscroll = () => {
    if (window.innerWidth >= 514) {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("nav").style.top = "0";
      } else {
        if (prevScrollpos > 64 || currentScrollPos > 64) {
          // 64 is the height of the navbar
          document.getElementById("nav").style.top = "-4rem";
        }
      }
      prevScrollpos = currentScrollPos;
    }
  };

  // change background image each click brand section
  const brand = document.getElementById("brand");
  const bgImgWrapper = document.getElementById("bg-img");
  const bgImgCollection = bgImgWrapper.children;
  const logo = document.getElementById("logo");

  let bgIdx = 0;

  brand.addEventListener("click", () => {
    changeBgImg();
  });

  // function that change background image each click brand section
  const changeBgImg = () => {
    logo.classList.add("animate-press");
    setTimeout(() => {
      logo.classList.remove("animate-press");
    }, 1000);

    setTimeout(() => {
      if (bgIdx < bgImgCollection.length - 1) {
        bgImgCollection[bgIdx].classList.add("invisible");
        bgImgCollection[bgIdx + 1].classList.remove("invisible");
        bgIdx++;
      } else {
        bgImgCollection[bgIdx].classList.add("invisible");
        bgImgCollection[0].classList.remove("invisible");
        bgIdx = 0;
      }
    }, 500);
  };

  // tsParticles

  const tsParticlesOptions = {
    fpsLimit: 60,
    fullScreen: {
      enable: false
    },
    background: {
      color: "transparent"
    },
    particles: {
      color: { value: "random" },
      links: {
        color: "random",
        distance: 150,
        enable: false,
        opacity: 0.4,
        width: 1
      },
      move: {
        attract: { enable: false, rotateX: 150, rotateY: 300 },
        bounce: false,
        direction: "none",
        enable: true,
        out_mode: "out",
        random: false,
        speed: 1,
        straight: false
      },
      rotate: {
        animation: {
          enable: true,
          speed: 1,
          sync: false
        }
      },
      number: { density: { enable: true, area: 800 }, value: 10 },
      // shape: {
      //   type: "images",
      //   images: [
      //     {
      //       src: "https://particles.js.org/images/WhatsApp.png"
      //     },
      //     {
      //       src: "https://particles.js.org/images/telegram.png"
      //     }
      //   ]
      // },
      shape: {
        character: [
          {
            fill: true,
            font: "Verdana",
            value: ["☮️", "🌈", "🍄", "✨"],
            style: "",
            weight: 400
          }
        ],
        polygon: { nb_sides: 5 },
        stroke: { color: "random", width: 1 },
        type: "char"
      },
      size: {
        anim: { enable: true, minimumValue: 16, speed: 2, sync: false },
        random: { minimumValue: 6, enable: true },
        value: 20
      },
      opacity: {
        animation: { enable: true, minimumValue: 0.5, speed: 1, sync: false },
        random: false,
        value: 1
      }
    },
    detectRetina: true
  };

  tsParticles.load("tsparticles", tsParticlesOptions);
  let tsParticlesOptions2 = {
    ...tsParticlesOptions
  };
  tsParticlesOptions2.particles.shape.character = [
    {
      fill: true,
      font: "Verdana",
      value: ["☮️", "🌈", "🌀", "🌞"],
      style: "",
      weight: 400
    }
  ];
  tsParticles.load("tsparticles2", tsParticlesOptions2);
  let tsParticlesOptions3 = {
    ...tsParticlesOptions
  };
  tsParticlesOptions3.particles.shape.character = [
    {
      fill: true,
      font: "Verdana",
      value: ["☮️", "🌈", "🎨", "🖼️"],
      style: "",
      weight: 400
    }
  ];
  tsParticles.load("tsparticles3", tsParticlesOptions3);

  // Marquee slide

  //   Variables
  // Play with this value to change the speed
  let tickerSpeed = 0.5;

  let flickity = null;
  let flickity2 = null;
  let isPaused = false;
  let isPaused2 = false;
  const slideshowEl = document.querySelector(".mrq-slide-container");
  const slideshow2El = document.querySelector("#mrq-slide-container-2");

  //   Functions
  const update = () => {
    if (isPaused) return;
    if (flickity.slides) {
      flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
      flickity.selectedIndex = flickity.dragEndRestingSelect();
      flickity.updateSelectedSlide();
      flickity.settle(flickity.x);
    }
    window.requestAnimationFrame(update);
  };
  const update2 = () => {
    if (isPaused2) return;
    if (flickity2.slides) {
      flickity2.x = (flickity2.x - tickerSpeed) % flickity2.slideableWidth;
      flickity2.selectedIndex = flickity2.dragEndRestingSelect();
      flickity2.updateSelectedSlide();
      flickity2.settle(flickity2.x);
    }
    window.requestAnimationFrame(update2);
  };

  const pause = () => {
    isPaused = true;
  };
  const pause2 = () => {
    isPaused2 = true;
  };

  const play = () => {
    if (isPaused) {
      isPaused = false;
      window.requestAnimationFrame(update);
    }
  };
  const play2 = () => {
    if (isPaused2) {
      isPaused2 = false;
      window.requestAnimationFrame(update2);
    }
  };

  //   Create Flickity
  flickity = new Flickity(slideshowEl, {
    autoPlay: false,
    prevNextButtons: false,
    pageDots: false,
    draggable: true,
    wrapAround: true,
    selectedAttraction: 0.015,
    friction: 0.25
  });
  flickity2 = new Flickity(slideshow2El, {
    autoPlay: false,
    prevNextButtons: false,
    pageDots: false,
    draggable: true,
    wrapAround: true,
    selectedAttraction: 0.015,
    friction: 0.25
  });

  flickity.x = 0;
  flickity2.x = 0;

  //   Add Event Listeners
  slideshowEl.addEventListener("mouseenter", pause, false);
  slideshowEl.addEventListener("focusin", pause, false);
  slideshowEl.addEventListener("mouseleave", play, false);
  slideshowEl.addEventListener("focusout", play, false);

  slideshow2El.addEventListener("mouseenter", pause2, false);
  slideshow2El.addEventListener("focusin", pause2, false);
  slideshow2El.addEventListener("mouseleave", play2, false);
  slideshow2El.addEventListener("focusout", play2, false);

  flickity.on("dragStart", () => {
    isPaused = true;
  });
  flickity2.on("dragStart", () => {
    isPaused2 = true;
  });

  //   Start Ticker
  update();
  update2();
};
